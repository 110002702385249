import { Button, Grid, Icon, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FieldFormComponent from "../components/fieldForm/FieldForm.component";
import {
  deleteFieldData,
  updateFieldData,
} from "../store/actions/fields.actions";
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";

const FieldUpdatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 767px");
  const [isModalOpen, setModalOpen] = useState(false);
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  const onSubmit = (values) => {
    dispatch(updateFieldData(values));
    handleGoBack();
  };

  const onDelete = (values) => {
    dispatch(deleteFieldData(values));
    handleGoBack();
  };

  const handleDeleteClick = () => {
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(field);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={handleGoBack}>
          <Icon>arrow_back</Icon>
        </Button>
        <h3>{t("fields-update-page.title")}</h3>
      </div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("fields-update-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={3}>
          <Button variant="contained" onClick={() => handleDeleteClick()}>
            {t("fields-page.button-delete-field")}
          </Button>
        </Grid>
      </Grid>
      <FieldFormComponent onSubmit={onSubmit}></FieldFormComponent>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t("sensors_form.confirmational_modal.title")}
        message={t("sensors_form.confirmational_modal.message")}
        cancelText={t("sensors_form.confirmational_modal.cancel_text")}
        confirmText={t("sensors_form.confirmational_modal.confirm_text")}
      />
    </Fragment>
  );
};

export default FieldUpdatePage;
