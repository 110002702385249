export const FETCH_ME_REQUEST = 'FETCH_ME_REQUEST';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';


export const meRequest = () => ({
  type: FETCH_ME_REQUEST
});


export const meSuccess = (data) => {
  return {
    type: FETCH_ME_SUCCESS,
    payload: data,
  };
};

export const meFailure = (error) => {
  return {
    type: FETCH_ME_FAILURE,
    payload: error.message
  };
};
