import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createData } from "shared-components/src/components/displayData/CustomizedTables.component";
import FixedSizeListComponent from "shared-components/src/components/displayData/FixedSizeList.component";
import MapComponent from "shared-components/src/components/map/map.component";

const MapFielComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const fieldSelected = useSelector((state) => state.field.data);
  const fields = useSelector((state) => state.fields.data);
  const field = fields.find((f) => f.id === fieldSelected);
  const polygons = field?.polygons ?? [];
  const sensors = field?.sensors ?? [];
  const sensors_data = field?.realtime?.sensors_data ?? [];

  const shapefile = "shapefiles/Nationnal_Parks_Italy.shp";

  const setSensorList = (sensors) => {
    return !sensors
      ? []
      : sensors.map((sensor, index) => index + 1 + ". " + sensor.name);
  };

  const sensorsList = setSensorList(sensors);

  const setSensorDataRealtime = (sensor_id, sensors_data) => {
    const data = sensors_data?.find((sensor) => sensor_id === sensor.id)?.data;
    return data ? data.map((d) => createData(d.label, d.value)) : null;
  };

  const setMarkersWithNumber = (sensors, sensors_data) => {
    return !sensors
      ? []
      : sensors.map((sensor, index) => {
          let popup = null;
          let popupWithTable = null;
          if (index === 1) {
            popup =
              '<div style="display: flex; align-items: center; background: #fce4d6; padding: 5px; border-radius: 7px;"><div style="background: orange; width: 20px; height: 20px; borderRadius: 50%; margin: 5px"></div>AAAA</div>';
          }
          if (index !== 1) {
            popupWithTable = {
              rows: setSensorDataRealtime(sensor.id, sensors_data),
              tableTitle: sensor.name,
            };
          }
          return {
            id: sensor.id,
            position: sensor.position,
            iconNumber: index + 1,
            color: theme.palette.marker.main,
            popup: popup,
            popupWithTable: popupWithTable,
            tooltip: sensor.name,
          };
        });
  };

  const markersWithNumber = setMarkersWithNumber(sensors, sensors_data);

  return (
    <Fragment>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={9} md={9}>
          <MapComponent
            shapefile={null}
            polygons={polygons}
            markersWithNumber={markersWithNumber}
          ></MapComponent>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div style={{ padding: "10px", paddingLeft: "16px" }}>
            <h4 style={{ margin: 0 }}>{t("map_field.title")}</h4>
          </div>
          <FixedSizeListComponent rows={sensorsList}></FixedSizeListComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default MapFielComponent;
