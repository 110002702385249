import { HIDE_ALERTINFO, SHOW_ALERTINFO } from "./alertInfo.actions";

const initialState = {
  type: null,
  message: null,
};

const alertInfo = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERTINFO:
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      };
    case HIDE_ALERTINFO:
      return {
        ...state,
        type: null,
        message: null,
      };
    default:
      return state;
  }
};

export default alertInfo;
