import { ADD_IRRIGATION, ADD_IRRIGATION_FAILURE, ADD_IRRIGATION_SUCCESS, DELETE_IRRIGATION, DELETE_IRRIGATION_FAILURE, DELETE_IRRIGATION_STATE, DELETE_IRRIGATION_SUCCESS, FETCH_IRRIGATIONS_FAILURE, FETCH_IRRIGATIONS_REQUEST, FETCH_IRRIGATIONS_SUCCESS } from "../actions/irrigations.actions";


const initialState = {
  data: null,
  loading: false,
};

const irrigations = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IRRIGATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IRRIGATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_IRRIGATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_IRRIGATION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_IRRIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_IRRIGATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_IRRIGATION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_IRRIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_IRRIGATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_IRRIGATION_STATE:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default irrigations;
