export const fieldInitialValues = {
  id: null,
  name: "",
  polygons: [
    { id: null, position: [], color: "blue", popup: null, tooltip: null },
  ],
  irrigations_system: {
    system: "",
    number: "",
    amount: "",
  },
  soil_type: {
    // type: "",
    clay: "",
    silt: "",
    sand: "",
  },
  crop: {
    type: "",
    species: "",
    variety_name: "",
    variety_code: "",
    distance_line: "",
    distance_individuals: "",
    planting_date: "",
    harvest_date: "",
    crop_yelds: "",
    flowering_date: "",
    rieping_date: "",
  },
  sensors: [],
  activities: {
    irrigation: [],
    soil_preparation: [],
    fertilizer: [],
    pesticide: [],
  },
  realtime: {
    irrigation_profile: {},
    transpiration_profile: {},
    suggested_irrigation: {},
    sensors_data: [],
  },
};

export const irrigationSystem = {
  sprinkler: "sprinkler",
  drip: "drip",
  suterranean: "suterranean",
};

export const soilType = {
  texture: "texture",
  field_capacity: "field_capacity",
  permanent_wilting_point: "permanent_wilting_point",
  bulk_density: "bulk_density",
  taw: "taw",
  raw: "raw",
};

export const cropType = {
  annual: "annual",
  perennial: "perennial",
};

export const speciesType = {
  tomato: "tomato",
  maize: "maize",
  potato: "potato",
  orange: "orange",
  onions: "onions",
};
