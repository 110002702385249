export const FETCH_FERTILIZERS_REQUEST = "FETCH_FERTILIZERS_REQUEST";
export const FETCH_FERTILIZERS_SUCCESS = "FETCH_FERTILIZERS_SUCCESS";
export const FETCH_FERTILIZERS_FAILURE = "FETCH_FERTILIZERS_FAILURE";
export const ADD_FERTILIZER = "ADD_FERTILIZER";
export const ADD_FERTILIZER_SUCCESS = "ADD_FERTILIZER_SUCCESS";
export const ADD_FERTILIZER_FAILURE = "ADD_FERTILIZER_FAILURE";
export const UPDATE_FERTILIZER = "UPDATE_FERTILIZER";
export const UPDATE_FERTILIZER_SUCCESS = "UPDATE_FERTILIZER_SUCCESS";
export const UPDATE_FERTILIZER_FAILURE = "UPDATE_FERTILIZER_FAILURE";
export const DELETE_FERTILIZER = "DELETE_FERTILIZER";
export const DELETE_FERTILIZER_SUCCESS = "DELETE_FERTILIZER_SUCCESS";
export const DELETE_FERTILIZER_FAILURE = "DELETE_FERTILIZER_FAILURE";
export const DELETE_FERTILIZER_STATE = "DELETE_FERTILIZER_STATE";

export const addFertilizerRequest = (data) => {
  return {
    type: ADD_FERTILIZER,
    payload: data,
  };
};

export const addFertilizerSuccess = (data) => {
  return {
    type: ADD_FERTILIZER_SUCCESS,
    payload: data,
  };
};

export const addFertilizerFailure = () => {
  return {
    type: ADD_FERTILIZER_FAILURE,
  };
};

export const fetchFertilizerRequest = (farmId) => {
  return {
    type: FETCH_FERTILIZERS_REQUEST,
    payload: farmId,
  };
};

export const fetchFertilizerSuccess = (data) => {
  return {
    type: FETCH_FERTILIZERS_SUCCESS,
    payload: data,
  };
};

export const fetchFertilizerFailure = () => {
  return {
    type: FETCH_FERTILIZERS_FAILURE,
  };
};

export const deleteFertilizerRequest = (data) => {
  return {
    type: DELETE_FERTILIZER,
    payload: data,
  };
};

export const deleteFertilizerSuccess = (data) => {
  return {
    type: DELETE_FERTILIZER_SUCCESS,
    payload: data,
  };
};

export const deleteFertilizerFailure = () => {
  return {
    type: DELETE_FERTILIZER_FAILURE,
  };
};

export const deleteFertilizerState = () => {
  return {
    type: DELETE_FERTILIZER_STATE,
  };
};