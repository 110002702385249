import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import FileUploader from "../components/fileUploader/FileUploader.component";
import { Button, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ShapefilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={handleGoBack}>
          <Icon>arrow_back</Icon>
        </Button>
        <h3>{t("shapefile.title")}</h3>
      </div>
      <FileUploader></FileUploader>
    </Fragment>
  );
};

export default ShapefilePage;
