export const FETCH_FIELDS_REQUEST = "FETCH_FIELDS_REQUEST";
export const FETCH_FIELDS_SUCCESS = "FETCH_FIELDS_SUCCESS";
export const FETCH_FIELDS_FAILURE = "FETCH_FIELDS_FAILURE";
export const ADD_FIELD = "ADD_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const DELETE_FIELD = "DELETE_FIELD";

export const fetchFieldsDataRequest = (farmId) => {
  return {
    type: FETCH_FIELDS_REQUEST,
    payload: farmId,
  };
};

export const fetchFieldsDataSuccess = (data) => {
  return {
    type: FETCH_FIELDS_SUCCESS,
    payload: data,
  };
};

export const fetchFieldsDataFailure = () => {
  return {
    type: FETCH_FIELDS_FAILURE,
  };
};

export const addFieldData = (field) => {
  return {
    type: ADD_FIELD,
    payload: field,
  };
};

export const updateFieldData = (field) => {
  return {
    type: UPDATE_FIELD,
    payload: field,
  };
};

export const deleteFieldData = (field) => {
  return {
    type: DELETE_FIELD,
    payload: field,
  };
};
