import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useSelector } from "react-redux";

const CircularProgressComponent = () => {
  const showProgress = useSelector(
    (state) => state.circularProgress.showProgress
  );

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showProgress}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CircularProgressComponent;
