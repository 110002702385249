export const irrigationTypeEnum = {
  trustedYou: "trusted_you",
  customAmount: "custom_amount",
};

export const irrigationLabelEnum = {
  trustedYou: "I trust you! (The platform decide for you)",
  definedAmount: "Irrigate a defined amount"
}

export const actionType = {
  irrigate: "irrigate",
};

export const sensorStatus = {
  ready: "ready",
  executed: "executed",
  wait: "wait",
  error: "error",
};
