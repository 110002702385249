import { all } from "redux-saga/effects";
import {
  watchCheckTokenLogin,
  watchLogin,
} from "shared-components/src/components/login/saga/auth.saga";
import watchMe from "shared-components/src/components/users/saga/users.saga";
import { watchAddActivity, watchGetActivity } from "./sagas/activities.saga";
import watchGetFarm from "./sagas/farm.saga";
import { watchAddFertilizer } from "./sagas/fertilizers.saga";
import {
  watchAddField,
  watchDeleteField,
  watchGetFields,
  watchUpdateField,
} from "./sagas/fields.saga";
import { watchAddIrrigation } from "./sagas/irrigations.saga";
import { watchAddPesticide } from "./sagas/pesticides.saga";
import { watchGetSensorsMeasure } from "./sagas/realtime.saga";
import {
  watchAddCommandSensor,
  watchAddSensor,
  watchDeleteSensor,
  watchFetchCommandSensor,
  watchUpdateSensor,
} from "./sagas/sensors.saga";
import { watchAddSoilPreparation } from "./sagas/soilPreparations.saga";
import { watchGetSensorMeasureData } from "./sagas/realtimeSensorMeasure.saga";
import { watchGetWeatherCondition } from "shared-components/src/components/weatherForecast/saga/weatherForecast.saga";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchCheckTokenLogin(),
    watchMe(),
    watchGetFarm(),
    watchGetFields(),
    watchAddField(),
    watchUpdateField(),
    watchDeleteField(),
    watchAddSensor(),
    watchDeleteSensor(),
    watchGetActivity(),
    watchAddActivity(),
    watchAddIrrigation(),
    watchAddSoilPreparation(),
    watchAddFertilizer(),
    watchAddPesticide(),
    watchGetSensorsMeasure(),
    watchGetSensorMeasureData(),
    watchAddCommandSensor(),
    watchFetchCommandSensor(),
    watchGetWeatherCondition(),
    watchUpdateSensor(),
  ]);
}
