import { FETCH_ME_FAILURE, FETCH_ME_REQUEST, FETCH_ME_SUCCESS } from "./users.actions";

const initialState = {
  data: null,
  loading: false,
};

const me = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_ME_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default me;
