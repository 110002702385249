import axios from 'axios';
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getApiService } from "../../../services/apiService";
import { API_GET_ME, API_POPULATE_ALL } from '../../../../../acquaount-dashboard-farm/src/config/api';
import { alertInfoTypes } from '../../feedback/alertInfoTypes.enum';
import { showAlertInfo } from '../../feedback/store/alertInfo.actions';
import { hideProgress } from '../../feedback/store/circularProgress.actions';
import { FETCH_ME_REQUEST, meFailure, meSuccess } from '../store/users.actions';

// const apiService = getApiService();

export const getMe = async (token) => {
  
  const response = await axios.get(API_GET_ME + API_POPULATE_ALL, {
  // const response = await axios.get(apiService.getMeUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleMe(action) {
  try {
    const token = yield select(state => state.auth.token);
    const meUserData = yield call(getMe, token);
    yield put(meSuccess(meUserData));
  } catch (error) {
    yield put(showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message }));
    yield put(hideProgress());
    console.log('Me error', error);
    yield put(meFailure(error.message));
  }
}

function* watchMe() {
  yield takeLatest(FETCH_ME_REQUEST, handleMe);
}

export default watchMe;
