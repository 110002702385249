import { Grid, TextField } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { soilType } from "../../config/fieldIntialValues";

const SoilTypeFormComponent = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div>
        <h4>{t("soilType.title")}</h4>
      </div>
      <p>
        {t(
          "Texture, Field capacity, Permanent wilting point, Bulk density, TAW, RAW"
        )}
      </p>

      <Grid container spacing={2}>
        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.texture)} ${t(
                "soilType.clay"
              )} ${t("soilType.unit.clay")}`}
              name="soil_type.clay"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.clay" component="div" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.texture)} ${t(
                "soilType.silt"
              )} ${t("soilType.unit.silt")}`}
              name="soil_type.silt"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.silt" component="div" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.texture)} ${t(
                "soilType.sand"
              )} ${t("soilType.unit.sand")}`}
              name="soil_type.sand"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.sand" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.field_capacity)} ${t(
                "soilType.unit." + soilType.field_capacity
              )}`}
              name="soil_type.field_capacity"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.field_capacity" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.permanent_wilting_point)} ${t(
                "soilType.unit." + soilType.permanent_wilting_point
              )}`}
              name="soil_type.permanent_wilting_point"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage
              name="soil_type.permanent_wilting_point"
              component="div"
            />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0, step: "any" }}
              as={TextField}
              label={`${t("soilType." + soilType.bulk_density)} ${t(
                "soilType.unit." + soilType.bulk_density
              )}`}
              name="soil_type.bulk_density"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.bulk_density" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.taw)} ${t(
                "soilType.unit." + soilType.taw
              )}`}
              name="soil_type.taw"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.taw" component="div" />
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item xs={12} sm={12} md={6}>
            <Field
              type="number"
              inputProps={{ min: 0 }}
              as={TextField}
              label={`${t("soilType." + soilType.raw)} ${t(
                "soilType.unit." + soilType.raw
              )}`}
              name="soil_type.raw"
              variant="outlined"
              fullWidth
            />
            <ErrorMessage name="soil_type.raw" component="div" />
          </Grid>
        </Fragment>
      </Grid>
    </Fragment>
  );
};

export default SoilTypeFormComponent;
