import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import React from "react";
import { useDispatch } from "react-redux";
import { logout, logOutUser } from "../login/store/auth.actions";
import AccountMenu from "./accountMenu.component";
import LanguagesMenu from "./languagesMenu.component";

const drawerWidth = 240;

const HeaderBar = (props) => {
  const { title, languages, languagesFlags, handleDrawerToggle, name } = props;
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOutUser());
    dispatch(logout());
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: red,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        padding: `0 !important`,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}>
          {name &&
            <div  sx={{ marginRight: "15px" }}>
              {name}
            </div>
          }
          <Button
            sx={{ marginRight: "10px" }}
            color="inherit"
            onClick={handleLogOut}
          >
            Logout
          </Button>
          <LanguagesMenu
            languages={languages}
            languagesFlags={languagesFlags}
          ></LanguagesMenu>
        </Box>
        <Box sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}>
          <AccountMenu
            languages={languages}
            languagesFlags={languagesFlags}
          ></AccountMenu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
