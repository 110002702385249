import axios from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import {
  API_ADD_FIELD_DATA,
  API_GET_FIELDS_DATA,
  API_UPDATE_FIELD_DATA,
} from "../../config/api";
import {
  ADD_FIELD,
  DELETE_FIELD,
  FETCH_FIELDS_REQUEST,
  UPDATE_FIELD,
  fetchFieldsDataFailure,
  fetchFieldsDataSuccess,
} from "../../store/actions/fields.actions";
import { setField } from "../../store/actions/field.actions";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import { addActivityRequest } from "../../store/actions/activities.actions";

const getFieldsData = async (token, farmId) => {
  const fieldsUrl = `${API_GET_FIELDS_DATA}/${farmId}`;
  const response = await axios.get(fieldsUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleFieldsData(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = action?.payload;
    yield put(showProgress());
    const response = yield call(getFieldsData, token, farmId);
    yield put(fetchFieldsDataSuccess(response));
    yield put(fetchOveviewData());
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchFieldsDataFailure(error.message));
  }
}

export function* watchGetFields() {
  yield takeLatest(FETCH_FIELDS_REQUEST, handleFieldsData);
}

const addField = async (token, field) => {
  const fieldsUrl = `${API_ADD_FIELD_DATA}`;
  const data = field;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(fieldsUrl, { data }, { headers });
  return response.data;
};

function* handleAddField(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    let field = action?.payload;
    field = { ...field, farm: farmId };
    yield put(showProgress());
    const response = yield call(addField, token, field);
    yield put(addActivityRequest(response.data.id));
    const fields = yield call(getFieldsData, token, farmId);
    yield put(fetchFieldsDataSuccess(fields));
    yield put(setField(response.data.id));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchFieldsDataFailure(error.message));
  }
}

export function* watchAddField() {
  yield takeLatest(ADD_FIELD, handleAddField);
}

const updateField = async (token, field) => {
  const fieldsUrl = `${API_UPDATE_FIELD_DATA}/${field.id}`;
  const data = field;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.put(fieldsUrl, { data }, { headers });
  return response.data;
};

function* handleUpdateField(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    let field = action?.payload;
    yield put(showProgress());
    const response = yield call(updateField, token, field);
    const fields = yield call(getFieldsData, token, farmId);
    yield put(fetchFieldsDataSuccess(fields));
    yield put(setField(response.data.id));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchFieldsDataFailure(error.message));
  }
}

export function* watchUpdateField() {
  yield takeLatest(UPDATE_FIELD, handleUpdateField);
}

const deleteField = async (token, field) => {
  const fieldsUrl = `${API_UPDATE_FIELD_DATA}/${field.id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.delete(fieldsUrl, { headers });
  return response.data;
};

function* handleDeleteField(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = yield select((state) => state.farm.data.id);
    let field = action?.payload;
    yield put(showProgress());
    yield call(deleteField, token, field);
    const fields = yield call(getFieldsData, token, farmId);
    yield put(setField(null));
    yield put(fetchFieldsDataSuccess(fields));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchFieldsDataFailure(error.message));
  }
}

export function* watchDeleteField() {
  yield takeLatest(DELETE_FIELD, handleDeleteField);
}
