import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Section = ({ titleKey, descriptionKey, titleAddition }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Typography fontWeight="bold">
        {t(titleKey)} - {t(titleAddition)}
      </Typography>
      <Typography marginBottom={1}>{t(descriptionKey)}</Typography>
    </Fragment>
  );
};

const WikiPage = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 767px");

  const sectionsHome = [
    {
      titleAddition: "wikiPage.home.menu",
      descriptionKey: "wikiPage.home.menu-description",
    },
    {
      titleAddition: "wikiPage.home.weather",
      descriptionKey: "wikiPage.home.weather-description",
    },
    {
      titleAddition: "wikiPage.home.overview",
      descriptionKey: "wikiPage.home.overview-description",
    },
    {
      titleAddition: "wikiPage.home.planning",
      descriptionKey: "wikiPage.home.planning-description",
    },
    {
      titleAddition: "wikiPage.home.forecast-water",
      descriptionKey: "wikiPage.home.forecast-water-description",
    },
  ];

  const sectionsFields = [
    {
      titleAddition: "wikiPage.fields.menu",
      descriptionKey: "wikiPage.fields.menu-description",
    },
    {
      titleAddition: "wikiPage.fields.map",
      descriptionKey: "wikiPage.fields.map-description",
    },
    {
      titleAddition: "wikiPage.fields.general-info-irrigation",
      descriptionKey: "wikiPage.fields.general-info-irrigation-description",
    },
    {
      titleAddition: "wikiPage.fields.general-info-soil",
      descriptionKey: "wikiPage.fields.general-info-soil-description",
    },
    {
      titleAddition: "wikiPage.fields.general-info-crop",
      descriptionKey: "wikiPage.fields.general-info-crop-description",
    },
    {
      titleAddition: "wikiPage.fields.latest-activities",
      descriptionKey: "wikiPage.fields.latest-activities-description",
    },
  ];

  const sectionsRealTime = [
    {
      titleAddition: "wikiPage.realtime.menu",
      descriptionKey: "wikiPage.realtime.menu-description",
    },
    {
      titleAddition: "wikiPage.realtime.irrigation-profile",
      descriptionKey: "wikiPage.realtime.irrigation-profile-description",
    },
    {
      titleAddition: "wikiPage.realtime.remote-irrigation",
      descriptionKey: "wikiPage.realtime.remote-irrigation-description",
    },
    {
      titleAddition: "wikiPage.realtime.breathability",
      descriptionKey: "wikiPage.realtime.breathability-description",
    },
    {
      titleAddition: "wikiPage.realtime.sensor-data",
      descriptionKey: "wikiPage.realtime.sensor-data-description",
    },
  ];

  const sectionsWiki = [
    {
      titleAddition: "wikiPage.wiki.menu",
      descriptionKey: "wikiPage.wiki.menu-description",
    },
  ];

  return (
    <Fragment>
      <h3>{t("wikiPage.title")}</h3>
      {isMobile ? null : <Typography>{t("wikiPage.subtitle")}</Typography>}
      <Box width="100%" height="auto" mt={3}>
        <Paper variant="elevation" sx={{ padding: 2 }}>
          <Box marginBottom={4}>
            {sectionsHome.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.home.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Box marginBottom={4}>
            {sectionsFields.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.fields.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Box marginBottom={4}>
            {sectionsRealTime.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.realtime.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Box marginBottom={4}>
            {sectionsWiki.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.wiki.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default WikiPage;
