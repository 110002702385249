import { ADD_PESTICIDE, ADD_PESTICIDE_FAILURE, ADD_PESTICIDE_SUCCESS, DELETE_PESTICIDE, DELETE_PESTICIDE_FAILURE, DELETE_PESTICIDE_STATE, DELETE_PESTICIDE_SUCCESS, FETCH_PESTICIDES_FAILURE, FETCH_PESTICIDES_REQUEST, FETCH_PESTICIDES_SUCCESS } from "../actions/pesticides.actions";


const initialState = {
  data: null,
  loading: false,
};

const pesticides = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PESTICIDES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PESTICIDES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_PESTICIDES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_PESTICIDE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_PESTICIDE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PESTICIDE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_PESTICIDE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_PESTICIDE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PESTICIDE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_PESTICIDE_STATE:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default pesticides;
