export const FETCH_SOIL_PREPARATIONS_REQUEST = "FETCH_SOIL_PREPARATIONS_REQUEST";
export const FETCH_SOIL_PREPARATIONS_SUCCESS = "FETCH_SOIL_PREPARATIONS_SUCCESS";
export const FETCH_SOIL_PREPARATIONS_FAILURE = "FETCH_SOIL_PREPARATIONS_FAILURE";
export const ADD_SOIL_PREPARATION = "ADD_SOIL_PREPARATION";
export const ADD_SOIL_PREPARATION_SUCCESS = "ADD_SOIL_PREPARATION_SUCCESS";
export const ADD_SOIL_PREPARATION_FAILURE = "ADD_SOIL_PREPARATION_FAILURE";
export const UPDATE_SOIL_PREPARATION = "UPDATE_SOIL_PREPARATION";
export const UPDATE_SOIL_PREPARATION_SUCCESS = "UPDATE_SOIL_PREPARATION_SUCCESS";
export const UPDATE_SOIL_PREPARATION_FAILURE = "UPDATE_SOIL_PREPARATION_FAILURE";
export const DELETE_SOIL_PREPARATION = "DELETE_SOIL_PREPARATION";
export const DELETE_SOIL_PREPARATION_SUCCESS = "DELETE_SOIL_PREPARATION_SUCCESS";
export const DELETE_SOIL_PREPARATION_FAILURE = "DELETE_SOIL_PREPARATION_FAILURE";
export const DELETE_SOIL_PREPARATION_STATE = "DELETE_SOIL_PREPARATION_STATE";

export const addSoilPreparationRequest = (data) => {
  return {
    type: ADD_SOIL_PREPARATION,
    payload: data,
  };
};

export const addSoilPreparationSuccess = (data) => {
  return {
    type: ADD_SOIL_PREPARATION_SUCCESS,
    payload: data,
  };
};

export const addSoilPreparationFailure = () => {
  return {
    type: ADD_SOIL_PREPARATION_FAILURE,
  };
};

export const fetchSoilPreparationRequest = (farmId) => {
  return {
    type: FETCH_SOIL_PREPARATIONS_REQUEST,
    payload: farmId,
  };
};

export const fetchSoilPreparationSuccess = (data) => {
  return {
    type: FETCH_SOIL_PREPARATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchSoilPreparationFailure = () => {
  return {
    type: FETCH_SOIL_PREPARATIONS_FAILURE,
  };
};

export const deleteSoilPreparationRequest = (data) => {
  return {
    type: DELETE_SOIL_PREPARATION,
    payload: data,
  };
};

export const deleteSoilPreparationSuccess = (data) => {
  return {
    type: DELETE_SOIL_PREPARATION_SUCCESS,
    payload: data,
  };
};

export const deleteSoilPreparationFailure = () => {
  return {
    type: DELETE_SOIL_PREPARATION_FAILURE,
  };
};

export const deleteSoilPreparationState = () => {
  return {
    type: DELETE_SOIL_PREPARATION_STATE,
  };
};