import 'leaflet/dist/leaflet.css';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { GeoJSON, MapContainer, Marker, Polygon, Popup, SVGOverlay, TileLayer, Tooltip } from 'react-leaflet';
import { read } from 'shapefile';

import DOMPurify from 'dompurify';
import L from 'leaflet';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideProgress, showProgress } from 'shared-components/src/components/feedback/store/circularProgress.actions';
import { routerPaths } from '../../../../acquaount-dashboard-farm/src/router/routerPaths';
import { setField } from '../../../../acquaount-dashboard-farm/src/store/actions/field.actions';
import CustomizedTables from '../displayData/CustomizedTables.component';
import createCustomIcon from './createCustomIcon';

const MapComponent = (props) => {
  const mapRef = useRef(null);
  const [geojsonData, setGeojsonData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mapHeight = props.mapHeight ?? '400px';
  const mapZoom = props.mapZoom ?? 7;
  const mapCenter = props.mapCenter ?? [40.356862371325384, 9.240084014976134];
  const mapUrl = props.mapUrl ?? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const attribution = props.attribution ?? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | Abinsula';
  const shapefile = props.shapefile ?? null;
  const polygons = props.polygons ?? null;
  const layers = props.layers ?? null;
  const markersWithNumber = props.markersWithNumber ?? null;

  const convertSHPtoGeoJSON = async () => {
    try {
      const response = await fetch(shapefile);
      const arrayBuffer = await response.arrayBuffer();
      const { features } = await read(arrayBuffer);
      const geojsonData = {
        type: 'FeatureCollection',
        features: features,
      };
      dispatch(hideProgress());
      return geojsonData;
    } catch (error) {
      console.error('Errore durante la conversione da SHP a GeoJSON:', error);
      return null;
    }
  };

  useEffect(() => {
    if (shapefile) {
      dispatch(showProgress());
      const convertAndSetGeoJSON = async () => {
        const convertedGeoJSON = await convertSHPtoGeoJSON();
        setGeojsonData(convertedGeoJSON);
      };
      convertAndSetGeoJSON();
    }
  }, []);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      if (map._layers) {
        const allLayerBounds = Object.values(map._layers).map(layer => layer._bounds);
        const allBounds = L.latLngBounds(allLayerBounds);
        if (allBounds.isValid()) {
          map.fitBounds(allBounds)
        }
      }
    }
  }, [mapRef.current, polygons, layers]);

  const sanitizedHTML = (value) => {
    return DOMPurify.sanitize(value);
  }

  const handlerLayer = (fieldId) => {
    dispatch(setField(fieldId));
    navigate(routerPaths.realtime);
  };

  return (
    <MapContainer center={mapCenter} zoom={mapZoom} style={{ height: mapHeight }} ref={mapRef}>
      <TileLayer
        url={mapUrl}
        attribution={attribution}
      />

      {geojsonData && <GeoJSON data={geojsonData} />}

      {polygons && polygons.map((polygon) => (
        <Polygon key={polygon.id} positions={polygon.position} color={polygon.color}>
          {polygon.popup && <Popup>{polygon.popup}</Popup>}
          {polygon.tooltip && <Tooltip>{polygon.tooltip}</Tooltip>}
        </Polygon>
      ))}

      {layers && layers.map((layer) => (
        <Fragment key={layer?.id}>
          {/* <ImageOverlayComponent key={layer.id} imageUrl={layer?.imageUrl} bounds={layer?.bounds} checkColor={layer?.color} field={layer?.field} /> */}

          <Polygon key={layer?.id} positions={layer?.bounds} color={layer?.color}>
            <Popup>
              <div style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => handlerLayer(layer?.field.id)}>
                {layer?.field.name}
              </div>
            </Popup>
          </Polygon>
          {layer?.bounds.length > 0 && <SVGOverlay key={'cir' + layer?.field.id} attributes={{ stroke: layer?.color }} bounds={layer?.bounds}>
            <circle r="10%" cx="50%" cy="50%" fill={layer?.color} />
          </SVGOverlay>}
        </Fragment>
      ))}

      {markersWithNumber && markersWithNumber.map((marker) => (
        <Marker key={marker.id} position={marker.position} icon={createCustomIcon({ number: marker.iconNumber, color: marker.color })} style={{ "background": "trasparent", "border": "none" }}>
          {marker.popup && <Popup><div dangerouslySetInnerHTML={{ __html: sanitizedHTML(marker.popup) }}></div></Popup>}
          {marker.tooltip && <Tooltip><div dangerouslySetInnerHTML={{ __html: sanitizedHTML(marker.tooltip) }}></div></Tooltip>}
          {marker.popupWithTable && <Popup><CustomizedTables rows={marker.popupWithTable.rows} tableTitle={marker.popupWithTable.tableTitle}></CustomizedTables></Popup>}
          {marker.tooltipWithTable && <Tooltip><CustomizedTables rows={marker.tooltipWithTable.rows} tableTitle={marker.tooltipWithTable.tableTitle}></CustomizedTables></Tooltip>}
        </Marker>
      ))}

    </MapContainer>
  );
}

export default MapComponent;
