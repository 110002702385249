import {
  FETCH_SENSOR_MEASURE_FAILURE,
  FETCH_SENSOR_MEASURE_REQUEST,
  FETCH_SENSOR_MEASURE_SUCCESS,
} from "../actions/realtimeSensorMeasure.actions";

const initialState = {
  data: null,
  loading: false,
};

const sensorMeasureData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SENSOR_MEASURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SENSOR_MEASURE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_SENSOR_MEASURE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default sensorMeasureData;
