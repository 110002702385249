import { List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

const FixedSizeListComponent = (props) => {
  const { rows, style } = props;

  return (
    <List
      sx={{
        width: "100%",
        maxHeight: 360,
        maxWidth: 360,
        bgcolor: "background.paper",
        overflow: "auto",
      }}
    >
      {rows &&
        rows.map((value) => (
          <ListItem style={style} key={value} component="div" disablePadding>
            <ListItemButton>
              <ListItemText primary={`${value}`} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

export default FixedSizeListComponent;
