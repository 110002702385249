export const SHOW_ALERTINFO = "SHOW_ALERTINFO";
export const HIDE_ALERTINFO = "HIDE_ALERTINFO";

export const showAlertInfo = (data) => ({
  type: SHOW_ALERTINFO,
  payload: data,
});

export const hideAlertInfo = () => ({
  type: HIDE_ALERTINFO,
});
