export const FETCH_WEATHERFORECAST_REQUEST = "FETCH_WEATHERFORECAST_REQUEST";
export const FETCH_WEATHERFORECAST_SUCCESS = "FETCH_WEATHERFORECAST_SUCCESS";
export const FETCH_WEATHERFORECAST_FAILURE = "FETCH_WEATHERFORECAST_FAILURE";

export const fetchWeatherForecastDataRequest = (position) => {
  return {
    type: FETCH_WEATHERFORECAST_REQUEST,
    payload: {position}
  };
};

export const fetchWeatherForecastDataSuccess = (data) => {
  return {
    type: FETCH_WEATHERFORECAST_SUCCESS,
    payload: data,
  };
};

export const fetchWeatherForecastDataFailure = () => {
  return {
    type: FETCH_WEATHERFORECAST_FAILURE,
  };
};

export const fetchWeatherForecastData = () => {
  return async (dispatch) => {
    dispatch(fetchWeatherForecastDataRequest());

    try {
      const response = await fetch("your-api-endpoint");
      const data = await response.json();
      dispatch(fetchWeatherForecastDataSuccess(data));
    } catch (error) {
      dispatch(fetchWeatherForecastDataFailure());
    }
  };
};
