import { FormControl, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import i18next from 'i18next';
import React, { Fragment, useState } from 'react';
import Flag from 'react-world-flags';

const LanguagesMenu = (props) => {

    const [language, setLanguage] = useState(i18next.language);
    const { languages, languagesFlags, languagesLightTemplate } = props;

    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18next.changeLanguage(event.target.value);
    };

    const languagesTemplate = languagesLightTemplate ?
        {            
            '& .MuiOutlinedInput-input': {
                padding: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        } :
        {
            minWidth: 140,
            '& .MuiOutlinedInput-input': {
                color: 'white',
            },
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        };

    return (
        <Fragment>
            <FormControl sx={languagesTemplate} size="small">
                <Select
                    id="demo-select-small"
                    value={language}
                    onChange={handleChange}
                >
                    {Object.keys(languages).map((key) => (
                        <MenuItem key={key} value={key}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Flag code={languagesFlags[key]} height={16} width={24} style={{ marginRight: '5px' }} />
                                <span>{languages[key]}</span>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Fragment>
    );
}

export default LanguagesMenu;
