export const FETCH_SENSOR_MEASURE_REQUEST = "FETCH_SENSOR_MEASURE_REQUEST";
export const FETCH_SENSOR_MEASURE_SUCCESS = "FETCH_SENSOR_MEASURE_SUCCESS";
export const FETCH_SENSOR_MEASURE_FAILURE = "FETCH_SENSOR_MEASURE_FAILURE";

export const fetchSensorMeasureDataRequest = (payload) => {
  return {
    type: FETCH_SENSOR_MEASURE_REQUEST,
    payload,
  };
};

export const fetchSensorMeasureDataSuccess = (data) => {
  return {
    type: FETCH_SENSOR_MEASURE_SUCCESS,
    payload: data,
  };
};

export const fetchSensorMeasureDataFailure = () => {
  return {
    type: FETCH_SENSOR_MEASURE_FAILURE,
  };
};
