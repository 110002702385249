import {
  FETCH_OVEVIEW_FAILURE,
  FETCH_OVEVIEW_REQUEST,
  FETCH_OVEVIEW_SUCCESS,
} from "../actions/oveview.actions";

const initialState = {
  data: [],
  loading: false,
};

const oveview = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OVEVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_OVEVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_OVEVIEW_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default oveview;
