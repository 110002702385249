import {
  ADD_COMMAND_FAILURE,
  ADD_COMMAND_REQUEST,
  ADD_COMMAND_SUCCESS,
  ADD_SENSOR,
  ADD_SENSOR_FAILURE,
  ADD_SENSOR_SUCCESS,
  DELETE_SENSOR,
  DELETE_SENSOR_FAILURE,
  DELETE_SENSOR_SUCCESS,
  FETCH_COMMAND_FAILURE,
  FETCH_COMMAND_REQUEST,
  FETCH_COMMAND_SUCCESS,
  UPDATE_SENSOR,
  UPDATE_SENSOR_FAILURE,
  UPDATE_SENSOR_SUCCESS,
} from "../actions/sensors.actions";

const initialState = {
  data: {
    device_id: null,
    commandStatus: null,
  },
  loading: false,
  error: false,
};

const sensors = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SENSOR:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_SENSOR:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_SENSOR:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_COMMAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_COMMAND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_COMMAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FETCH_COMMAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMMAND_SUCCESS:
      return {
        ...state,
        commandstatus: action.payload.data,
        loading: false,
      };
    case FETCH_COMMAND_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default sensors;
