import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { hideAlertInfo } from "./store/alertInfo.actions";

const AlertInfComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alertInfo = useSelector((state) => state.alertInfo);

  const closeAlert = () => {
    dispatch(hideAlertInfo());
  };

  return (
    <Stack sx={{ width: "100%", marginBottom: "10px" }} spacing={2}>
      {alertInfo?.type && (
        <Alert
          variant="outlined"
          severity={alertInfo?.type}
          onClose={() => {
            closeAlert();
          }}
        >
          {alertInfo?.message}
        </Alert>
      )}
    </Stack>
  );
};

export default AlertInfComponent;
