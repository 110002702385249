import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_ALL_SENSORS_MEASURE } from "../../config/api";
import {
  FETCH_ALL_SENSORS_MEASURE_REQUEST,
  fetchSensorsMeasureFailure,
  fetchSensorsMeasureSuccess,
} from "../../store/actions/realtime.actions";

const getSensorsMeasure = async (token, field) => {
  const url = `${API_ALL_SENSORS_MEASURE}`;
  const data = { field_name: field };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(url, data, { headers });
  return response.data;
};

function* handleSensorsMeasure(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const field = action?.payload;
    const response = yield call(getSensorsMeasure, token, field);
    yield put(fetchSensorsMeasureSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    yield put(fetchSensorsMeasureFailure(error.message));
  }
}

export function* watchGetSensorsMeasure() {
  yield takeLatest(FETCH_ALL_SENSORS_MEASURE_REQUEST, handleSensorsMeasure);
}
