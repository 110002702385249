import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomizedTables, {
  createData,
} from "shared-components/src/components/displayData/CustomizedTables.component";
import * as Yup from "yup";
import { addFertilizerRequest } from "../../store/actions/fertilizers.actions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ActivitiesFertilizerComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const fieldSelected = useSelector((state) => state.field.data);
  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const activities = useSelector((state) => state?.activities?.data);
  const fertilizers = activities?.attributes?.fertilizers?.data?.map(
    (fertilizer) => ({
      type: fertilizer?.attributes?.type,
      date: fertilizer?.attributes?.date,
      quantity: fertilizer?.attributes?.quantity,
    })
  );

  const initialValues = {
    type: "",
    date: "",
    quantity: "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    quantity: Yup.string().required("Required"),
  });

  const createRowDataObject = (data) => {
    return Object.keys(data).map((key) => {
      const value = key === "quantity" ? `${data[key]} kg/ha` : data[key];
      return createData(key, value);
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      let newActivity = {
        type: value.type,
        date: value.date,
        quantity: value.quantity,
      };
      newActivity = { ...newActivity, activity: field?.activity?.id };

      dispatch(addFertilizerRequest(newActivity));

      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4>{t("activities_fertilizer.title")}</h4>
            <div
              onClick={() => setViewAll(!viewAll)}
              style={{ cursor: "pointer" }}
            >
              <h5>{viewAll ? t("view_last") : t("view_all")}</h5>
            </div>
          </div>
        </Grid>
        {!viewAll && activities && fertilizers?.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <CustomizedTables
              rows={createRowDataObject(fertilizers[fertilizers.length - 1])}
            ></CustomizedTables>
          </Grid>
        )}
        {viewAll &&
          activities &&
          fertilizers?.length > 0 &&
          fertilizers.map((pest, index) => (
            <Grid item key={index} xs={12} sm={12} md={12} lg={6}>
              <CustomizedTables
                rows={createRowDataObject(pest)}
              ></CustomizedTables>
            </Grid>
          ))}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              {t("add_new")}
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {t("activities_fertilizer.form_add_fertilizer.dialog_title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t(
                          "activities_fertilizer.form_add_fertilizer.type"
                        )}
                        name={`type`}
                        variant="outlined"
                        fullWidth
                      />
                      <ErrorMessage name={`type`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t(
                            "activities_fertilizer.form_add_fertilizer.date"
                          )}
                          name="date"
                          // value={initialValues.date}
                          onChange={(date) => setFieldValue("date", date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name={`date`} component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t(
                          "activities_fertilizer.form_add_fertilizer.quantity"
                        )}
                        name={`quantity`}
                        variant="outlined"
                        fullWidth
                      />
                      <ErrorMessage name={`quantity`} component="div" />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("add_activity")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
};

export default ActivitiesFertilizerComponent;
