import axios from "axios";
import {
  API_WEATHER_DATA,
  weatherApiKey,
} from "../../../../../acquaount-dashboard-farm/src/config/api";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
/* import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions"; */
import {
  FETCH_WEATHERFORECAST_REQUEST,
  fetchWeatherForecastDataFailure,
  fetchWeatherForecastDataSuccess,
} from "../store/actions/weatherForecast.actions";

const getWeatherConditions = async (token, coordinates) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(`${API_WEATHER_DATA}`, {
    params: {
      key: weatherApiKey,
      q: `${coordinates[0]},${coordinates[1]}`,
      days: 2,
    },
    headers: headers,
  });
  return response.data;
};

function* handleWeatherConditions(action) {
  try {
    //yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const defaultPosition = ["39.992541620116896", "11.273016700660706"];
    const coordinates = action.payload.position || defaultPosition;
    const days = 2;
    const response = yield call(getWeatherConditions, token, coordinates, days);
    yield put(fetchWeatherForecastDataSuccess(response));
    //yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    //yield put(hideProgress());
    yield put(fetchWeatherForecastDataFailure(error.message));
  }
}

export function* watchGetWeatherCondition() {
  yield takeLatest(FETCH_WEATHERFORECAST_REQUEST, handleWeatherConditions);
}
