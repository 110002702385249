export const languages = {
  en: "English",
  fr: "Français",
  it: "Italiano",
};

export const languagesFlags = {
  en: "gb",
  fr: "fr",
  it: "it",
};
