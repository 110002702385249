import {
  FETCH_FIELDS_FAILURE,
  FETCH_FIELDS_REQUEST,
  FETCH_FIELDS_SUCCESS,
} from "../actions/fields.actions";

const initialState = {
  data: [],
  loading: false,
};

const fields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FIELDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    // case ADD_FIELD:
    //   return {
    //     ...state,
    //     // data: [...state.data, action.payload],
    //     data: action.payload,
    //   };
    // case UPDATE_FIELD:
    //   const updatedData = state.data.map(item => {
    //     if (item.id === action.payload.id) {
    //       return {
    //         ...item,
    //         ...action.payload,
    //       };
    //     }
    //     return item;
    //   });
    //   return {
    //     ...state,
    //     data: updatedData,
    //   };
    default:
      return state;
  }
};

export default fields;
