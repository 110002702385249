import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
};

const labels = [];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "",
      backgroundColor: "",
    },
  ],
};

const LineChartComponent = (props) => {
  const linearChartData = props.data ?? data;
  const linearChartOptions = props.options ?? options;

  return <Line options={linearChartOptions} data={linearChartData} />;
};

export default LineChartComponent;
