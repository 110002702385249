export const routerPaths = {
  login: "/login",
  home: "/home",
  fields: "/fields",
  realtime: "/realtime",
  wiki: "/wiki",
  field_add: "/fields/field-add",
  field_update: "/fields/field-update",
  shapefile: "/fields/shapefile",
};
