import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MenuSide = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { menuList, logo } = props;

  const imageStyle = {
    maxHeight: "64px",
    width: "200px",
  };

  return (
    <Fragment>
      <Toolbar>
        {logo && <img src={logo} alt="Acquaount" style={imageStyle} />}
      </Toolbar>
      <Box sx={{ overflow: "auto" }}>
        <Divider />
        <List>
          {menuList &&
            Object.keys(menuList).map((key) => (
              <ListItem key={key} disablePadding>
                <ListItemButton
                  onClick={() => navigate(menuList[key].path)}
                  selected={
                    window.location.pathname === `/${menuList[key].path}`
                  }
                >
                  <ListItemIcon>
                    <Icon>{menuList[key].icon}</Icon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="h6">
                      {t(`menu.${menuList[key].label}`)}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </Fragment>
  );
};

export default MenuSide;
