import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MapComponent from "shared-components/src/components/map/map.component";

const MapHomeComponent = () => {
  const { t } = useTranslation();

  const layers = useSelector((state) => state.oveview).data;

  return (
    <Fragment>
      <MapComponent
        shapefile={null}
        polygons={null}
        layers={layers}
        markersWithNumber={null}
      ></MapComponent>
      <div
        style={{ display: "flex", alignItems: "center", background: "#fce4d6" }}
      >
        <div
          style={{
            background: "orange",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            margin: "5px",
          }}
        ></div>
        <span style={{ fontWeight: "bold" }}>
          {t("map-oveview.check-changes")}
        </span>
      </div>
    </Fragment>
  );
};

export default MapHomeComponent;
