export const FETCH_PLANNING_REQUEST = "FETCH_PLANNING_REQUEST";
export const FETCH_PLANNING_SUCCESS = "FETCH_PLANNING_SUCCESS";
export const FETCH_PLANNING_FAILURE = "FETCH_PLANNING_FAILURE";

export const fetchPlanningDataRequest = () => {
  return {
    type: FETCH_PLANNING_REQUEST,
  };
};

export const fetchPlanningDataSuccess = (data) => {
  return {
    type: FETCH_PLANNING_SUCCESS,
    payload: data,
  };
};

const fetchPlanningDataFailure = () => {
  return {
    type: FETCH_PLANNING_FAILURE,
  };
};

export const fetchPlanningData = () => {
  return async (dispatch) => {
    dispatch(fetchPlanningDataRequest());

    try {
      const response = await fetch("your-api-endpoint");
      const data = await response.json();
      dispatch(fetchPlanningDataSuccess(data));
    } catch (error) {
      dispatch(fetchPlanningDataFailure());
    }
  };
};
