export const SET_FIELD = "SET_FIELD";
export const RESET_FIELD = "RESET_FIELD";

export const setField = (field) => {
  return {
    type: SET_FIELD,
    payload: field,
  };
};

export const resetField = () => {
  return {
    type: RESET_FIELD,
  };
};
