export const FETCH_ALL_SENSORS_MEASURE_REQUEST =
  "FETCH_ALL_SENSORS_MEASURE_REQUEST";
export const FETCH_ALL_SENSORS_MEASURE_SUCCESS =
  "FETCH_ALL_SENSORS_MEASURE_SUCCESS";
export const FETCH_ALL_SENSORS_MEASURE_FAILURE =
  "FETCH_ALL_SENSORS_MEASURE_FAILURE";

export const fetchSensorsMeasureRequest = (filed) => {
  return {
    type: FETCH_ALL_SENSORS_MEASURE_REQUEST,
    payload: filed,
  };
};

export const fetchSensorsMeasureSuccess = (data) => {
  return {
    type: FETCH_ALL_SENSORS_MEASURE_SUCCESS,
    payload: data,
  };
};

export const fetchSensorsMeasureFailure = () => {
  return {
    type: FETCH_ALL_SENSORS_MEASURE_FAILURE,
  };
};
