import { Box, Chip, Grid, Paper, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import MapHomeComponent from "../components/map/MapHome.component";
import { useSelector } from "react-redux";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import HomeAreaChartComponent from "shared-components/src/components/charts/homeAreaChart.component";
import WeatherForecastComponent from "shared-components/src/components/weatherForecast/WeatherForecastNew.component";

//const labels = ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"];

/* const data = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Historical min",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      backgroundColor: "rgb(255, 99, 132)",
      data: [8, 8, 8, 8, 8, 8, 8],
      fill: false,
    },
    {
      type: "line",
      label: "Historical max",
      borderColor: "rgb(75, 192, 192)",
      borderWidth: 2,
      backgroundColor: "rgb(75, 192, 192)",
      data: [84, 84, 84, 84, 84, 84, 84],
      fill: false,
    },
    {
      type: "bar",
      label: "Dataset",
      backgroundColor: "rgb(53, 162, 235)",
      data: [60, 55, 60, 63, 68, 70, 75],
    },
  ],
}; */

const HomePage = () => {
  const { t } = useTranslation();
  const weatherForecast = useSelector((state) => state.weatherForecast).data;
  const position = useSelector(
    (state) => state.fields?.data?.[0]?.polygons?.[0]?.position?.[0]
  );
  const isMobile = useMediaQuery("(max-width: 767px");

  return (
    <Fragment>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        paddingTop={2}
      >
        <Grid item xs={12} md={4}>
          {weatherForecast && (
            <Paper
              variant="outlined"
              sx={{
                height: "auto",
                paddingX: "20px",
                paddingBottom: "20px",
                marginBottom: isMobile ? undefined : "10px",
              }}
            >
              <h3>{t("home.weather-forecast")}</h3>
              <div style={{ minHeight: "320px" }}>
                <WeatherForecastComponent position={position} />
              </div>
            </Paper>
          )}
          {!isMobile && (
            <Paper
              variant="outlined"
              sx={{ height: "auto", paddingX: "20px", paddingBottom: "20px" }}
            >
              <h3>{t("home.months-water-forecast")}</h3>
              <HomeAreaChartComponent />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
              >
                <h3>{t("home.oveview")}</h3>
                <MapHomeComponent></MapHomeComponent>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
              >
                <h3>{t("home.planning")}</h3>
                {/* <PlanningComponent></PlanningComponent> */}
                <Box
                  width="100%"
                  height="400px"
                  border="0.5px solid lightgrey"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="10px"
                >
                  <Chip
                    icon={<PrecisionManufacturingIcon />}
                    label="Under development"
                    variant="outlined"
                    color="warning"
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
          {isMobile && (
            <Paper
              variant="outlined"
              sx={{
                height: "auto",
                paddingX: "20px",
                paddingBottom: "20px",
                marginTop: isMobile ? "10px" : undefined,
              }}
            >
              <h3>{t("home.months-water-forecast")}</h3>
              <HomeAreaChartComponent />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HomePage;
