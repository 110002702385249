import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_URL_LOGIN } from "../../../../../acquaount-dashboard-farm/src/config/api";
import { alertInfoTypes } from "../../feedback/alertInfoTypes.enum";
import { showAlertInfo } from "../../feedback/store/alertInfo.actions";
import { hideProgress } from "../../feedback/store/circularProgress.actions";
import { getMe } from "../../users/saga/users.saga";
import authService from "../auth.service";
import {
  CHECK_TOKEN_REQUEST,
  LOGIN_REQUEST,
  loginFailure,
  loginSuccess,
} from "../store/auth.actions";

const loginUser = async (identifier, password) => {
  const response = await axios.post(API_URL_LOGIN, { identifier, password });
  return response.data;
};

function* handleLogin(action) {
  try {
    const { username, password, remember } = action.payload;
    const response = yield call(loginUser, username, password);
    const authData = authService.loginRemember(response.jwt, remember, null);
    yield put(loginSuccess(authData));
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    console.log("Login serror", error);
    yield put(loginFailure(error.message));
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}

function* handleCheckLogin(action) {
  try {
    yield call(getMe, action.payload.token);
    yield put(loginSuccess(action.payload));
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message })
    );
    yield put(hideProgress());
    console.log("CheckLogin error", error);
    yield put(loginFailure(error.message));
  }
}

export function* watchCheckTokenLogin() {
  yield takeLatest(CHECK_TOKEN_REQUEST, handleCheckLogin);
}
