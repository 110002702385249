import {
  ADD_FARM,
  FETCH_FARM_FAILURE,
  FETCH_FARM_REQUEST,
  FETCH_FARM_SUCCESS,
  UPDATE_FARM,
} from "../actions/farm.actions";

const initialState = {
  data: [],
  loading: false,
};

const farm = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FARM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FARM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_FARM_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_FARM:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case UPDATE_FARM:
      const updatedData = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      return {
        ...state,
        data: updatedData,
      };
    default:
      return state;
  }
};

export default farm;
