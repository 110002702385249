import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_SENSOR_MEASURE_DATA } from "../../config/api";
import {
  FETCH_SENSOR_MEASURE_REQUEST,
  fetchSensorMeasureDataFailure,
  fetchSensorMeasureDataSuccess,
} from "../../store/actions/realtimeSensorMeasure.actions";

const getSensorMeasureData = async (
  token,
  field,
  name,
  start_time,
  end_time,
) => {
  const url = `${API_SENSOR_MEASURE_DATA}?field_name=${field}&name=${name}&start_time=${start_time}&end_time=${end_time}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  return response.data;
};

function* handleSensorMeasureData(action) {
  try {
    yield put(showProgress());
    const token = yield select((state) => state.auth.token);
    const field = action?.payload.field;
    const name = action?.payload.datastream_name;
    const start_time = action?.payload.start_time;
    const end_time = action?.payload.end_time;
    const response = yield call(
      getSensorMeasureData,
      token,
      field,
      name,
      start_time,
      end_time,
    );
    yield put(fetchSensorMeasureDataSuccess(response));
    yield put(hideProgress());
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message }),
    );
    yield put(hideProgress());
    yield put(fetchSensorMeasureDataFailure(error.message));
  }
}

export function* watchGetSensorMeasureData() {
  yield takeLatest(FETCH_SENSOR_MEASURE_REQUEST, handleSensorMeasureData);
}
