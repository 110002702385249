import { useTheme } from "@mui/material/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SuggestedIrrigationComponent = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const fieldSelected = useSelector((state) => state.field.data);
  const fields = useSelector((state) => state.fields.data);
  const field = fields.find((f) => f.id === fieldSelected);

  const quantity = field?.realtime?.suggested_irrigation?.quantity ?? "50";
  const dateOptimal = field?.realtime?.suggested_irrigation?.optimal ?? "05/07/2024";
  const dateRisk = field?.realtime?.suggested_irrigation?.risk ?? "12/07/2024";
  const dateLate = field?.realtime?.suggested_irrigation?.late ?? "15/07/2024";

  return (
    <Fragment>
      <h4>{t("suggested-irrigation.title")}</h4>
      <div
        style={{ textAlign: "center", border: "1px solid", fontWeight: "bold" }}
      >
        <div style={{ padding: "12px" }}>
          <div>
            {t("suggested-irrigation.measurement-unit-label")}: {quantity}{" "}
            {t("suggested-irrigation.measurement-unit")}
          </div>
        </div>
        <div
          style={{
            background: theme.palette.optimal.main,
            padding: "12px",
            borderTop: "1px solid",
          }}
        >
          {dateOptimal}
          <div>{t("suggested-irrigation.date-optimal")}</div>
        </div>
        <div
          style={{
            background: theme.palette.risk.main,
            padding: "12px",
            borderTop: "1px solid",
          }}
        >
          {dateRisk}
          <div>{t("suggested-irrigation.date-risk")}</div>
        </div>
        <div
          style={{
            background: theme.palette.late.main,
            padding: "12px",
            borderTop: "1px solid",
          }}
        >
          {dateLate}
          <div>{t("suggested-irrigation.date-late")}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default SuggestedIrrigationComponent;
