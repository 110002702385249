import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { hideProgress } from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_GET_FARM_DATA } from "../../config/api";
import {
  FETCH_FARM_REQUEST,
  fetchFarmDataFailure,
  fetchFarmDataSuccess,
} from "../../store/actions/farm.actions";

const getFarmData = async (token, farmId) => {
  const farmUrl = `${API_GET_FARM_DATA}/${farmId}`;
  const response = await axios.get(farmUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function* handleFarmData(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const farmId = action?.payload;
    const response = yield call(getFarmData, token, farmId);
    yield put(fetchFarmDataSuccess(response.data));
  } catch (error) {
    yield put(
      showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message }),
    );
    yield put(hideProgress());
    yield put(fetchFarmDataFailure(error.message));
  }
}

export default function* watchGetFarm() {
  yield takeLatest(FETCH_FARM_REQUEST, handleFarmData);
}
