export const FETCH_FARM_REQUEST = "FETCH_FARM_REQUEST";
export const FETCH_FARM_SUCCESS = "FETCH_FARM_SUCCESS";
export const FETCH_FARM_FAILURE = "FETCH_FARM_FAILURE";
export const ADD_FARM = "ADD_FARM";
export const UPDATE_FARM = "UPDATE_FARM";

export const fetchFarmDataRequest = (farmId) => {
  return {
    type: FETCH_FARM_REQUEST,
    payload: farmId,
  };
};

export const fetchFarmDataSuccess = (data) => {
  return {
    type: FETCH_FARM_SUCCESS,
    payload: data,
  };
};

export const fetchFarmDataFailure = () => {
  return {
    type: FETCH_FARM_FAILURE,
  };
};

export const addFarmData = (farm) => {
  return {
    type: ADD_FARM,
    payload: farm,
  };
};

export const updateFarmData = (farm) => {
  return {
    type: UPDATE_FARM,
    payload: farm,
  };
};
