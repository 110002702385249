import store from "../store";

export const FETCH_OVEVIEW_REQUEST = "FETCH_OVEVIEW_REQUEST";
export const FETCH_OVEVIEW_SUCCESS = "FETCH_OVEVIEW_SUCCESS";
export const FETCH_OVEVIEW_FAILURE = "FETCH_OVEVIEW_FAILURE";

export const fetchOveviewDataRequest = () => {
  return {
    type: FETCH_OVEVIEW_REQUEST,
  };
};

export const fetchOveviewDataSuccess = (data) => {
  return {
    type: FETCH_OVEVIEW_SUCCESS,
    payload: data,
  };
};

const fetchOveviewDataFailure = () => {
  return {
    type: FETCH_OVEVIEW_FAILURE,
  };
};

export const fetchOveviewData = () => {
  return async (dispatch) => {
    dispatch(fetchOveviewDataRequest());

    try {
      const fields = store.getState().fields?.data;
      const data = fields?.map((field) => ({
        id: field.id,
        bounds: field?.polygons[0]?.position,
        color: getOverviewColor(field),
        name: field.name,
        field: field,
      }));
      dispatch(fetchOveviewDataSuccess(data));
    } catch (error) {
      dispatch(fetchOveviewDataFailure());
    }
  };
};

const getOverviewColor = (field) => {
  const blue = "#4674c2";
  const orange = "#ea7c3c";
  let color = field.id % 2 !== 0 ? blue : orange;
  return color;
};
