import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import { API_IRRIGATIONS } from "../../config/api";
import { ADD_IRRIGATION, addIrrigationFailure, addIrrigationSuccess } from "../../store/actions/irrigations.actions";
import { fetchFieldsDataRequest } from "../../store/actions/fields.actions";
import { fetchOveviewData } from "../../store/actions/oveview.actions";
import { fetchActivityRequest } from "../../store/actions/activities.actions";

const addIrrigation = async (token, activity) => {
    const fieldsUrl = `${API_IRRIGATIONS}`;
    const data = activity;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(fieldsUrl, { data }, { headers });
    return response.data;
  };
  
  function* handleAddIrrigation(action) {
    try {
      yield put(showProgress());
      const token = yield select((state) => state.auth.token);
      const farmId = yield select((state) => state.farm.data.id);
      const activity = action?.payload;
      const response = yield call(addIrrigation, token, activity);
      yield put(addIrrigationSuccess(response));
      yield put(fetchActivityRequest(activity.activity));
      yield put(fetchFieldsDataRequest(farmId));
      yield put(fetchOveviewData());
      yield put(hideProgress());
    } catch (error) {
      yield put(
        showAlertInfo({
          type: alertInfoTypes.ERROR,
          message: error?.response?.data?.error?.message ?? error.message,
        })
      );
      yield put(addIrrigationFailure(error.message));
      yield put(hideProgress());
    }
  }
  
  export function* watchAddIrrigation() {
    yield takeLatest(ADD_IRRIGATION, handleAddIrrigation);
  }

