class AuthService {
  loginRemember(token, remember, expiry) {
    if (remember) {
      localStorage.setItem(
        "acquaount",
        JSON.stringify({ token: token, expiry: expiry })
      );
    }
    return { token: token, expiry: expiry };
  }

  isAuthenticated(token) {
    return token ? true : false;
  }

  isRemember() {
    const data = JSON.parse(localStorage.getItem("acquaount"));

    if (data?.token) {
      return data;
    }
  }

  async logout() {
    localStorage.clear();
    return true;
  }
}

export default new AuthService();
