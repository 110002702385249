import { SHOW_PROGRESS, HIDE_PROGRESS } from "./circularProgress.actions";

const initialState = {
  showProgress: false,
};

const circularProgress = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PROGRESS:
      return {
        ...state,
        showProgress: true,
      };
    case HIDE_PROGRESS:
      return {
        ...state,
        showProgress: false,
      };
    default:
      return state;
  }
};

export default circularProgress;
