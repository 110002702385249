import React, { useState } from "react";
import { Button, CircularProgress, Alert } from "@mui/material";

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setShowAlert(false);
  };

  const handleUpload = () => {
    setIsLoading(true);
    // Simula il caricamento del file
    setTimeout(() => {
      setIsLoading(false);
      setShowAlert(true);
    }, 2000);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>
      {isLoading && <CircularProgress />}
      {showAlert && (
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          File caricato con successo!
        </Alert>
      )}
    </div>
  );
};

export default FileUploader;
